window.toggleSlideInMenu = () => {
  document.querySelector('body').classList.toggle('showing-mobile-menu')
  document.querySelector('.navbar').classList.toggle('show-menu')
  let button = document.querySelector('.nav-burger__button')
  button.getAttribute('aria-expanded') == 'false'
    ? button.setAttribute('aria-expanded', 'true')
    : button.setAttribute('aria-expanded', 'false')
}

document.addEventListener('turbolinks:before-render', () => {
  document.querySelector('body').classList.remove('showing-mobile-menu')
  document.querySelector('.navbar')?.classList.remove('show-menu')
  document
    .querySelector('.nav-burger__button')
    ?.setAttribute('aria-expanded', 'false')
})
