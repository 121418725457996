import intlTelInput from 'intl-tel-input'

import en from 'intl-tel-input/build/js/i18n/en'
import fr from 'intl-tel-input/build/js/i18n/fr'
import de from 'intl-tel-input/build/js/i18n/de'
import sv from 'intl-tel-input/build/js/i18n/sv'
import no from 'intl-tel-input/build/js/i18n/no'

import i18n from '../i18n/i18n.js.erb'

const I18n = window.I18n || i18n

window.intlTelInput = intlTelInput
window.intlTelInputI18n = {
  en: en,
  fr: fr,
  de: de,
  sv: sv,
  nb: no,
}

const languageCountryMap = {
  sv: 'se',
  de: 'de',
  fr: 'fr',
  nb: 'no',
}

let countryOrder = Object.values(languageCountryMap)

window.initIntlTelInputs = (selector) => {
  if (window.currentEvent) {
    countryOrder = [window.currentEvent.country.toLowerCase()]
    window.currentEvent.languages.forEach((lang) => {
      if (
        languageCountryMap[lang] &&
        !countryOrder.includes(languageCountryMap[lang])
      ) {
        countryOrder.push(languageCountryMap[lang])
      }
    })
  }

  document.querySelectorAll(selector).forEach((input) => {
    if (!window.intlTelInput.getInstance(input)) {
      intlTelInput(input, {
        loadUtilsOnInit: window.intlTelInputUtilsUrl,
        i18n: intlTelInputI18n[I18n.locale],
        autoPlaceholder: 'aggressive',
        formatOnDisplay: true,
        countryOrder: countryOrder,
        initialCountry: countryOrder[0],
        hiddenInput: () => {
          return {
            phone: input.dataset.hiddenInput || input.name,
            country: 'intlTelInput[country]',
          }
        },
      })
    }
  })
}
;['DOMContentLoaded', 'turbolinks:load'].forEach((event) => {
  document.addEventListener(event, () => {
    initIntlTelInputs('.intl-tel-input')
  })
})
