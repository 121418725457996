function fileFieldUploadFeedback(input, message) {
  input.value = ''
  input.parentElement.classList.remove('success')
  input.parentElement.querySelector('.profile-field-file-binary-input').value =
    ''
  let label = input.parentElement.querySelector(
    '.profile-field-file-upload-label'
  )
  label.innerHTML = `<span class='label-text'>${label.dataset.defaultLabel}</span>`
  fieldFeedback($(input), { kind: 'error', icon: 'remove', message: message })
}

$(document).on('change', '.profile-field-file-input', function (e) {
  const input = e.target

  clearFieldFeedback($(input))

  if (input.dataset.required && input.dataset.required != '') {
    input.required = JSON.parse(input.dataset.required)
  }

  if (!input.files || input.files.length < 1) {
    input.parentElement.classList.remove('success')
    input.parentElement.querySelector(
      '.profile-field-file-binary-input'
    ).value = ''
    let label = input.parentElement.querySelector(
      '.profile-field-file-upload-label'
    )
    label.innerHTML = `<span class='label-text'>${label.dataset.defaultLabel}</span>`
    return
  }

  const file = input.files[0]
  const uploadPath = input.parentElement.dataset.uploadPath
  const parentContainer = input.parentElement

  if (
    input.accept &&
    input.accept != '*' &&
    !input.accept.split(',').includes(file.type)
  ) {
    fileFieldUploadFeedback(
      input,
      I18n.t(`js.field_accepts_${input.accept.replaceAll(',', '_')}`)
    )
    return
  }

  const maxFileSize = 30
  if (file.size > maxFileSize * 1000000) {
    fileFieldUploadFeedback(
      input,
      `${I18n.t('js.max_file_size')} ${maxFileSize} mb`
    )
    return
  }

  let formData = new FormData()
  formData.append('file', file)

  showLoadingOverlay(I18n.t('js.file_is_uploading'))

  $.ajax({
    url: uploadPath,
    type: 'POST',
    data: formData,
    contentType: false,
    processData: false,
    complete: function (response) {
      const json = JSON.parse(response?.responseText)
      if (json?.binary_file_id) {
        parentContainer.querySelector(
          '.profile-field-file-binary-input'
        ).value = json.binary_file_id
        parentContainer.querySelector(
          '.profile-field-file-upload-label'
        ).innerHTML =
          `<span class='label-text'>${json?.file_url?.replace(/^.*[\\\/]/, '')}</span>`
        input.value = ''
        input.dataset.required = input.required
        input.required = false
        parentContainer.classList.add('success')
        if ($('form.edit_order').length > 0) {
          saveOrderParams($('form.edit_order'))
        }
      } else {
        fileFieldUploadFeedback(input, I18n.t('js.file_upload_error'))
      }
      hideLoadingOverlay()
    },
  })
})

$(document).on('click', '.profile-field-file-upload-remove', function (e) {
  const profileFielFiledUpload = $(this).parents('.profile-field-file-upload')
  const profileFieldFileInput = profileFielFiledUpload.find(
    '.profile-field-file-input'
  )
  profileFieldFileInput[0].value = ''
  profileFieldFileInput.trigger('change')
  if ($('form.edit_order').length > 0) {
    saveOrderParams($('form.edit_order'))
  }
})

$(document).on('dragenter', '.profile-field-file-upload-label', function (e) {
  e.preventDefault()
  $(this).parents('.profile-field-file-upload').addClass('with-file-dragged')
})

$(document).on('dragover', '.profile-field-file-upload-label', function (e) {
  e.preventDefault()
})

$(document).on('dragleave', '.profile-field-file-upload-label', function (e) {
  e.preventDefault()
  $(this).parents('.profile-field-file-upload').removeClass('with-file-dragged')
})

$(document).on('drop', '.profile-field-file-upload-label', function (e) {
  e.preventDefault()
  const profileFieldFileUpload = $(this).parents('.profile-field-file-upload')
  const profileFieldFileInput = profileFieldFileUpload.find(
    '.profile-field-file-input'
  )
  profileFieldFileUpload.removeClass('with-file-dragged')
  const files = e.originalEvent.dataTransfer.files
  profileFieldFileInput[0].files = files
  profileFieldFileInput.trigger('change')
})
